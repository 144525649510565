body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: gotham-medium;
  src: url(./assets/fonts/Gotham-Medium.otf);
}
@font-face {
  font-family: gotham-book;
  src: url(./assets/fonts/Gotham-Book.otf);
}
@font-face {
  font-family: engravers;
  src: url(./assets/fonts/engravers.otf);
}
@font-face {
  font-family:bodon;
  src: url(./assets/fonts/Bodoni.ttf);
}
@font-face {
  font-family:didot;
  src: url(./assets/fonts/Didot.ttc);
}
body {
background-color: #fff;
max-width:100%;
}
.container-idiomas-l{
display: flex;
text-align: center;
justify-content:center;
}
.container-idiomas{
display: flex;
text-align: center;
justify-content:center;
}
.container-boton-legal{

  /* display: flex; */
  text-align: center;
  justify-content:center;
font-size: 13px;
}
.container-boton-legal button::after{
border: 1px solid #A87E4F;
}
.boton-idioma{
  width: 135px;
  background: transparent;
  color: #A87E4F;
  border: 1px solid #A87E4F;
  padding: 10px 25px;
  font-family: gotham-book;
  margin:15px auto;
}

@media (min-width: 768px) {
  .boton-idioma{
    width: 135px;
    margin:15px 15px;
  }
.container-idiomas-l{
  display: flex;
  text-align: center;
  justify-content:center;
}
.container-idiomas{
  display: flex;
  text-align: center;
  justify-content:flex-start;
  margin:10px 0;
}
.container-boton-legal{
  display: flex;
  text-align: center;
  justify-content:center;
font-size: 13px;
}

.container-boton-legal button{
  margin: 10px 20px !important;
}
}
/* ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ 
  color: #fff !important;
  opacity: 1; /* Firefox 
  text-align: center;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 
  color: #fff !important;
  text-align: center;
}

::-ms-input-placeholder { /* Microsoft Edge 
  color: #fff !important;
  text-align: center;
} */

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #A87E4F !important;
opacity: 1; /* Firefox */
text-align: center;
font-family: gotham-medium;
font-size: 10px;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #A87E4F !important;
text-align: center;
font-family: gotham-medium;

}

::-ms-input-placeholder { /* Microsoft Edge */
color: #A87E4F !important;
text-align: center;
font-family: gotham-medium;
font-size: 14px;
}



.btn {

  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #6F7748;
  color: #fff;
  font-family: roboto-thin;
  margin: 10px 0 0;
  /* float: right; */
}
.btn:hover {
opacity: 0.7;
color:#fff;
}
.alert.alert-success {
background-color: #fff !important;
color: #000 !important;
border: none !important;
}
.navbar-toggler:focus {
 outline: none;
}
.only-mobile {
display: none;
}
.only-desktop {
display: block;
}
p {
color: #FFFFFF;
font-family: roboto-thin;
}
h3 {
color: #FFFFFF;
text-align: center;
font-family: alegreyasc-regular;
}
a:hover {
text-decoration: none;
}

/* PRELOADER */
#preloader {
  position: fixed;
  top:0; left:0;
  right:0; bottom:0;
  background: #000;
  z-index: 1100;
}
#loader {
  width: 100px;
  height: 100px;
  position: absolute;
  left:50%; top:50%;
  margin:-50px 0 0 -50px;
}

/* LEGALES */

/* SECCION LEGALES */
#legales-section {

background-image: url(./assets/img/fondo-legales.webp);
/* height: 100vh; */
min-height: 100vh;
background-position: center;
background-size: cover;
}
#wrapper-info-legales {
margin-top:70px;
}
#img-legales {
width: 450px;
margin-bottom: 30px;
}
#img-txt-legales {
  width: 500px;
  margin-bottom: 30px;
}
#li-barrita {
border-right: 3px solid transparent;
-moz-border-image:url("./assets/img/barrita-legales.png") 3 3 ; /* Old firefox */
-webkit-border-image:url("./assets/img/barrita-legales.png") 3 3 ; /* Safari */
-o-border-image:url("./assets/img/barrita-legales.png") 3 3 ; /* Opera */
border-image:url("./assets/img/barrita-legales.png") 3 3 ;
}
/* .title-legales {
color:#A87E4F;
font-family: gotham-medium;
font-size: 12px;
text-align: center;
} */
.title-legales {
color:#A87E4F;
font-family: gotham-medium;
font-size: 20px;
text-align: center;
letter-spacing: 1px;
margin-bottom: 15px;
}
#list-legales {
width: 100px;
height: 50px;
margin: 5px auto 0;
padding: 0;
}
#list-legales li {
float: left;
width: 50px;
list-style: none;
text-align: center;
}
#list-legales li a:hover{
color: #A87E4F;
border-bottom: 2px solid #A87E4F;
padding-bottom: 5px;
}

/* MENÚ */
.navbar {
height: 150px;
position: fixed;
width: 100%;
z-index: 1000;
top: 0;
}
#logo-header {
width: 185px;
}
.navbar-nav {
float: right;
display: block;
width: 150px;
height: 100px;
margin: 0;
}
.navbar-nav a{
color:#A87E4F !important;
font-family: gotham-medium;
font-size: 11px;
text-align: left;
padding: 0 0 5px;
transition: all 2s;
}
.navbar-nav a:hover {
color: #000 !important;
}
.nav-item {
margin-left: 0;
transition: all 2s;
}
.nav-item:hover {
margin-left: -65px;
}
#nav-item-wine-friends {
margin-left: -75px;
}
#nav-item-wine-friends a {
color: #000 !important;
}
.nav-item a:hover {
color: #000;
}


/* SECCION PRINCIPAL */
#home {
height: 100vh;
min-height: 700px;
padding-top: 0;
padding-bottom: 0;
/* background-attachment: fixed;
background-position: center;
background-size: cover;
background-image: url(../img/fondo-home.png); */
background-color: #000;
position: relative;
overflow: hidden;
margin-top: 150px;
}
#home video {
position: absolute;
top: 50%;
left: 50%;
width: 100%;
height: 100%;
z-index: 0;
background-position: center;
background-size: cover;
-ms-transform: translateX(-50%) translateY(-50%);
-moz-transform: translateX(-50%) translateY(-50%);
-webkit-transform: translateX(-50%) translateY(-50%);
transform: translateX(-50%) translateY(-50%);

}
#home .container {
position: relative;
z-index: 2;
}
.wrapper-title-home {
margin-top: 100px;
min-width: 400px;
}
.wrapper-titles-home{
width: 100%;
float: left;
height: 70px;
}
.title-home {
color: #A87E4F;
font-family: engravers;
font-size: 50px;
text-align: left;
float: left;
line-height: 98px;
margin:0 10px 0 0;

}
.title-home-dos {
color: #A87E4F;
font-family: bodon;
font-style: italic;
font-size: 50px;
text-align: left;
float: left;
}
.didot {
  font-family: didot !important;
  }

/* SECCION NOSOTROS HOME*/

#nosotros {
position: relative;
background-image: url(./assets/img/fondo-nosotros.png);
background-position: center;
background-size: cover;
padding: 50px 0 0px;
height: auto;
}
.title-nosotros{
text-align: left;
font-family: engravers;
color: #000;
font-weight: bold;
font-size: 29px;
line-height: 40px;
float: left;
}
.border-negro{
width: 1px;
height: 150px;
float: left;
background-color: #000;
-webkit-transform: skew(160deg);
   -moz-transform: skew(160deg);
   -ms-transform: skew(160deg);
   -o-transform: skew(160deg);
   transform: skew(160deg);
margin-left: 20px;
}
.txt-nosotros {
font-family: bodon;
font-style: italic;
text-align: left;
color: #000;
font-size: 25px;

}
#wrapper-nosotros-segundo {
background-image: url(./assets/img/fondo-wrapper-nosotros.png);
background-position: center;
background-size: cover;
padding: 75px 0 0;
height: 575px;
margin-top: 200px
}

.title-nosotros-wrapper{
font-family: engravers;
text-align: left;
color: #A87E4F;
font-weight: bold;
font-size: 29px;
line-height: 40px;
float: left;
}
.border-dorado{
width: 1px;
height: 150px;
float: left;
background-color: #A87E4F;
-webkit-transform: skew(160deg);
   -moz-transform: skew(160deg);
   -ms-transform: skew(160deg);
   -o-transform: skew(160deg);
   transform: skew(160deg);
}
.txt-nosotros-wrapper {
font-family: bodon;
font-style: italic;
text-align: left;
color: #A87E4F;
font-size: 25px;
}
.txt-firma {
color: #fff;
font-family: gotham-medium;
font-size: 12px;
text-align: center;
margin: 0;
}
.txt-firma-bajada {
color: #fff;
font-family: gotham-medium;
font-style: italic;
font-size: 12px;
text-align: center;
margin: 0;
}
/* VIAJE SIN FIN HOME*/
#viaje-sin-fin {
height: auto;
padding: 75px 0 50px;
background-color: #fff;
}

.title-viaje-sin-fin{
font-family: engravers;
text-align: left;
color: #A87E4F;
font-weight: bold;
font-size: 29px;
line-height: 40px;
float: left;
}
.border-dorado-sin-fin{
width: 1px;
height: 150px;
float: right;
margin-right: 35px;
background-color: #A87E4F;
-webkit-transform: skew(160deg);
   -moz-transform: skew(160deg);
   -ms-transform: skew(160deg);
   -o-transform: skew(160deg);
   transform: skew(160deg);
}
.txt-viaje-sin-fin {
font-family: bodon;
font-style: italic;
text-align: left;
color: #A87E4F;
font-size: 25px;

}

/* SECCION FOTO HOME */
#foto-home {
height: 100vh;
min-height: 700px;
padding-top: 0;
padding-bottom: 0;
background-attachment: fixed;
background-position: center;
background-size: cover;
background-image: url(./assets/img/fondo-foto-home.jpeg);
}

/* SECCION VINOS HOME */
#vinos-home {
/* background-color: #fff; */
background-image: url("./assets/img/Corte-mapa-montanas.gif");
background-size: 100% 80%;
background-position: bottom;
height: auto;
padding: 75px 10px;
}
#vinos-home .col-sm-5, #vinos-home .col-sm-2 {
padding: 0;
}
.title-principal-vinos-home {
font-family: engravers;
text-align: left;
color: #A87E4F;
font-weight: bold;
font-size: 29px;
line-height: 35px;
float: left;
}
.txt-principal-vinos-home {
font-family: bodon;
font-style: italic;
text-align: left;
color: #A87E4F;
font-size: 21px;
}
.title-vinos-home {
color: #000;
font-family: engravers;
font-size: 26px;
text-align: center;
}
.title-vinos-home-dorado {
color: #A87E4F;
font-family: engravers;
font-size: 26px;
margin:0;
}
.img-botellas {
margin-top: 20px;
}
.btn-vinos-home {
width: 150px;
margin: 30px auto 10px;
border: 1px solid #000;
cursor: pointer;
transition: 2s all;
}
.btn-vinos-home:hover {
background-color: #A87E4F;
color: #000;
border: 1px solid transparent;
}
.wrappera-nosotros-txt {
  margin-top: -5px;
}
.txt-vinos-home {
color: #A87E4F;
font-family: gotham-medium;
font-size: 12px;
text-align: center;
line-height: 12px;
margin: 0;
padding: 10px 0;
transition: 2s all;
}
.txt-vinos-home:hover {
color: #000;
}
#img-rodrigo {
position: absolute;
right: 0;
margin: 0;
max-width: 43%;
z-index: 0;
bottom: 0;
}
/* SECCION CONTACTO HOME*/

#contacto {
background-color: #f6f6f6;
height: 348px;
}
#logo-footer {
margin-top: 30px;
float: left;
}
#list-menu-footer {
float: left;
height: 200px;
margin: 100px 0 0 50px;
list-style: none;
}
#list-menu-footer li {
color: #000;
font-family: gotham-medium;
font-size: 11px;
line-height: 30px;
transition: 1s all;
}
#list-menu-footer li:hover {
color: #A87E4F;
}
#list-redes-footer {
width: 250px;
float: right;
margin: 100px 0 0;
}
.ico-footer{
margin-right: 20px;
transition: 1s all;
float: right;
}
.ico-footer:hover{
opacity: 0.7;
}
#footer-final {
border-top: 1px solid #A87E4F;
padding: 0 40px;
}
.txt-footer-final {
color: #000;
font-family: gotham-medium;
font-size: 14px;
transition: 1s all;
padding: 10px 0;
float: right;
margin: 0;
}
#nav-link-footer {
padding: 0;
}


/* ---------------- */

/* SECCION PRINCIPAL */
#home-replica{
height: 100vh;
min-height: 700px;
padding-top: 0;
padding-bottom: 0;
background-attachment: fixed;
background-position: center;
background-size: cover;
background-image: url(./assets/img/fondo-vino-malbec.jpeg);
position: relative;
overflow: hidden;
margin-top: 150px;
}

.wrapper-title-home-replica {
margin-top: 85px;
}
.wrapper-titles-home-replica{
width: 100%;
float: left;
height: 50px;
}
.title-home-replica {
color: #fff;
font-family: engravers;
font-size: 32px;
text-align: left;
float: left;
line-height: 98px;
margin:0 10px 0 0;

}
.title-home-dos-replica  {
color: #fff;
font-family: bodon;
font-style: italic;
font-size: 32px;
text-align: left;
float: left;
margin:0;
line-height: 75px;
}

/* SECCION VINOS HOME */
#vinos-home-replica {
background-color: #f8f9fa;
height: auto;
padding: 0px 0 50px;
position: relative;
}
#vinos-home-2-replica {
background-color: #fff;
height: auto;
padding: 0px;
position: relative;
}
#vinos-home-replica .col-sm-5, #vinos-home-replica .col-sm-4,
#vinos-home-2-replica .col-sm-5, #vinos-home-2-replica .col-sm-4{
padding: 0;
}
#vinos-home-replica .col-sm-4, #vinos-home-2-replica .col-sm-4{
margin-left: -50px;
z-index: 1;
}
.title-vinos-home-replica {
color: #000;
font-family: engravers;
font-size: 26px;
text-align: center;
margin: 0;
line-height: 24px;
}
.title-vinos-home-dorado-replica {
color: #A87E4F;
font-family: engravers;
font-size: 26px;
margin:0;
}
.img-botellas-replica {
margin-top: 40px;
}
.wrapper-ficha-tenica{
margin: 150px 0 100px;
float: left;
}
.title-ficha {
color: #A87E4F;
font-family: bodon;
font-style: italic;
font-size: 26px;
text-align: left;
float: left;
line-height: 18px;
margin: 0 7px 0 0;
}
.txt-ficha-inicial {
color: #000;
font-family: gotham-medium;
font-size: 14px;
text-align: left;
float: left;
margin: 10px 5px 0;
line-height: 25px;
}
.txt-ficha {
color: #000;
font-family: gotham-book;
font-size: 14px;
text-align: left;
margin: 0;
float: left;
}
.txt-ficha-inicial {
color: #000;
font-family: gotham-book;
font-size: 14px;
text-align: left;
margin: 0;
float: left;
}
.txt-ficha-final {
color: #000;
font-family: gotham-book;
font-size: 14px;
text-align: left;
float: left;
width: 25%;
margin: 20px 0 0;
}
.img-fichas {
float: right;
width: 76%;
height: auto;
margin: 20px 12% 0;
}
#img-mapa-mendoza {
position: absolute;
right: 0;
margin: 0;
max-width: 33%;
z-index: 0;
}
.btn-ficha-tecnica {
position: absolute;
height: 50px;
width: 193px;
border: 2px solid #f6f6f6;
background-color: #000;
transition: 2s all;
bottom: 20px;
left: 0;
}
.btn-ficha-tecnica:hover{
background-color: #000;
}
.txt-ficha-tecnica {
text-align: center;
font-size: 12px;
line-height: 48px;
color: #A87E4F;
font-family: gotham-medium;

}

.btn-para-comprar{
height: 48px;
width: 280px;
/* border: 2px solid #000; */
transition: 2s all;
background-color: #000;
position: absolute;
bottom: 20px;
left: 230px;
z-index: 1;
}
.btn-para-comprar:hover{
background-color: #A87E4F;
}
.txt-para-comprar {
text-align: center;
font-size: 12px;
line-height: 48px;
color: #A87E4F;
font-family: gotham-medium;
transition: 2s all;
}
.btn-para-comprar:hover .txt-para-comprar {
color: #fff;
}
/* SECCION HOME 2 */
#home-2-replica {
height: 100vh;
min-height: 700px;
padding-top: 0;
padding-bottom: 0;
background-attachment: fixed;
background-position: center;
background-size: cover;
background-image: url(./assets/img/fondo-vino-carmenere.jpeg);
position: relative;
overflow: hidden;
}

/* SECCION CONTACTO HOME*/

#contacto {
background-color: #f6f6f6;
height: 348px;
position: relative;
z-index: 2;
}
#logo-footer {
margin-top: 30px;
float: left;
}
#list-menu-footer {
float: left;
height: 200px;
margin: 100px 0 0 50px;
list-style: none;
}
#list-menu-footer li {
color: #000;
font-family: gotham-medium;
font-size: 11px;
line-height: 30px;
transition: 1s all;
}
#list-menu-footer li:hover {
color: #A87E4F;
}
#list-redes-footer {
width: 250px;
float: right;
margin: 100px 0 0;
}
.ico-footer{
margin-right: 20px;
transition: 1s all;
float: right;
}
.ico-footer:hover{
opacity: 0.7;
}
#footer-final {
border-top: 1px solid #A87E4F;
padding: 0 40px;
}
.txt-footer-final {
color: #000;
font-family: gotham-medium;
font-size: 14px;
transition: 1s all;
padding: 10px 0;
float: right;
margin: 0;
}
#nav-link-footer {
padding: 0;
}

/* ------------------ */

/* --------replica2------- */


#home-replica2 {
height: 100vh;
min-height: 700px;
padding-top: 0;
padding-bottom: 0;
/* background-attachment: fixed;
background-position: center;
background-size: cover;
background-image: url(../img/fondo-home.png); */
background-color: #000;
position: relative;
overflow: hidden;
/* margin-top: 50px; */
}
#home-replica2 video {
position: absolute;
top: 50%;
left: 50%;
width: 100%;
height: 100%;
z-index: 0;
background-position: center;
background-size: cover;
-ms-transform: translateX(-50%) translateY(-50%);
-moz-transform: translateX(-50%) translateY(-50%);
-webkit-transform: translateX(-50%) translateY(-50%);
transform: translateX(-50%) translateY(-50%);

}
#home-replica2 .container {
position: relative;
z-index: 2;
}
.wrapper-title-home-replica2 {
margin-top: 300px;

}
.wrapper-titles-home-replica2{
width: 100%;
float: left;
height: 70px;
}
.title-home-wine-friends {
color: #a87e4f;
font-family: bodon;
font-style: italic;
font-size: 40px;
text-align: left;
float: left;
line-height: 70px;
margin:0 10px 0 0;

}

.title-home-wine-friends-dos {
color: #A87E4F;
font-family: engravers;
font-size: 42px;
text-align: left;
line-height: 98px;

}

/* SECCION FORMULARIO */

#formulario {
padding: 50px 0;
}
.title-form {
color: #A87E4F;
font-family: bodon;
font-style: italic;
font-size: 30px;
text-align: center;
margin-bottom: 50px;
}
.txt-form {
color: #000;
font-family: bodon;
font-style: italic;
font-size: 20px;
text-align: center;
margin: 0;
}
.container-cajas-tempora{
text-align: center;
}
.cajas-tempora{
width: 100%;
max-width: 800px;
}

#contactForm {
margin: 10px auto 200px;
display: block;
}
.form-group input{
border-top: none;
border-right: none;
border-left:none;
border-radius: 0;
padding-left: 0;
padding-bottom: 0;
margin-bottom: 25px;
width: 100%;
}
#input_mensaje {
border-top: none;
border-right: none;
border-left:none;
border-radius: 0;
padding-left: 0;
padding-bottom: 0;
margin-bottom: 40px;
width: 100%;
height: 100px;
}
.btn_enviar {
/* float: right; */
height: 50px;
width: 193px;
border: 2px solid #f6f6f6;
text-align: center;
color: #A87E4F;
font-family: gotham-medium;
font-size: 12px;
line-height: 36px;
margin: 0;
transition: 2s all;
background-color: #fff;
}
.btn_enviar:hover{
background-color: #000;
}
#wrapper-txt-contacto-final {
margin: 20px 0 20px;
float: left;
-webkit-transform: rotate(-3deg);
-moz-transform: rotate(-3deg);
-o-transform: rotate(-3deg);
}
.btn-para-unirse{
height: 48px;
width: 280px;
transition: 2s all;
background-color: #000;
margin: 0 auto;
}
.btn-para-unirse:hover{
background-color: #A87E4F;
}
.txt-para-unirse {
text-align: center;
font-size: 12px;
line-height: 48px;
color: #A87E4F;
font-family: gotham-medium;
transition: 2s all;
}
.btn-para-unirse:hover .txt-para-unirse {
color: #fff;
}
#wrapper-form{
border-bottom: 1px solid #A87E4F;
padding: 0 20px 75px 20px;
margin-bottom: 75px;
}
/* .title-vinos-home-replica2 {
color: #000;
font-family: engravers;
font-size: 26px;
text-align: center;
} */
/* .title-vinos-home-dorado {
color: #A87E4F;
font-family: engravers;
font-size: 26px;
margin:0;
} */
/* .txt-vinos-home {
color: #A87E4F;
font-family: gotham-medium;
font-size: 12px;
text-align: center;
line-height: 12px;
margin: 0;
padding: 10px 0;
transition: 2s all;
} */
/* .txt-vinos-home:hover {
color: #000;
}
.img-botellas {
margin-top: 20px;
}
.btn-vinos-home {
width: 150px;
margin: 30px auto 10px;
border: 1px solid #000;
cursor: pointer;
transition: 2s all;
}
.btn-vinos-home:hover {
background-color: #A87E4F;
color: #000;
border: 1px solid transparent;
} */


/* -----------finreplica2--------- */


@media(max-width:1200px){
  .container-edit{
    max-width: 100%;
      }
}
/* STYLE MOBILE */
@media (max-width: 768px) {

#vinos-home {
  
  background-image: none;
  background-size:  0;
  background-position: center;
  background-color: #fff;

}
#legales-section{
padding: 8%; 
}
.title-legales{
font-size: 13px;
}
.only-mobile {
  display: block;
}
.only-desktop {
  display: none;
}
.navbar-collapse {
  position: absolute;
  background-color: #f8f9fa;
  width: 100%;
  top: 90px;
  left: 0;
  padding: 0 20px;
}
#nav-item-vino {
  margin-left: 9px;
}
.navbar-nav {
  float: left;
  height: auto;
  margin: 20px 0;
}
.nav-item {
  margin: 15px 10px;
}
.navbar-toggler {
  position: absolute;
  top: 20px;
  left: 10px;
  border: none;
}
.navbar-toggler span {
  color: #a87e4f;
}
.navbar-brand {
  width: 180px;
  margin: -5px auto 0;
}
#logo-header {
  width: 150px;
}
.navbar {
  height: 120px;
}
.navbar-nav {
  width: 100%;
}
.nav-link {
  text-align: center !important;
}

#home {
  background-color: #f8f9fa;
  height: auto;
  min-height: 350px;
  margin-top: 120px;
}
.wrapper-title-home {
  margin-top: 20px;
}
.wrapper-titles-home {
  width: 100%;
  float: left;
  height: 40px;
  margin: 0 auto;
}
.title-home{
  text-align: center;
  font-size: 26px;
  line-height: 30px;
  float: none;
  margin: 0 5px 0 0;
}
.title-home-dos{
  text-align: center;
  font-size: 26px;
  line-height: 30px;
  float: none;
  margin-top: -8px;
  margin-right: 0px;
}

#home video {
  top: 65%;
}

/* SECCION FOTO HOME */
#foto-home {
  background-attachment:scroll;
}
  /*MOBILE NOSOTROS< */

#nosotros {
  padding: 20px 0 0;
}
.title-nosotros {
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  float: none;
}
.border-negro-mobile {
  width: 150px;
  height: 1px;
  background-color: #000;
  margin: -10px auto 25px;
}
.txt-nosotros {
  text-align: center;
  font-size: 18px;
}
#wrapper-nosotros-segundo {
  margin-top: 100px;
  height: 550px;
}
.title-nosotros-wrapper {
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  float: none;
}
.border-dorado-mobile {
  width: 150px;
  height: 1px;
  float: none;
  margin: 0px auto 20px;
  background-color: #A87E4F;
}
.txt-nosotros-wrapper {
  text-align: center;
  font-size: 18px;
}
#img-rodrigo {
  max-width: 50%;
  bottom: 0;
}
/*MOBILE VIAJE< */

#viaje-sin-fin {
  padding: 25px 0;
}
.title-viaje-sin-fin {
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  float: none;
}
.border-negro-mobile {
  width: 150px;
  height: 1px;
  background-color: #000;
  margin: 0px auto 20px;
}
.txt-nosotros {
  text-align: center;
  font-size: 18px;
}
.txt-viaje-sin-fin {
  text-align: center;
  font-size: 18px;
}
/*MOBILE VINOS< */
#vinos-home-replica {
  padding: 25px 0;
}
.title-principal-vinos-home {
  text-align: center;
  font-size: 24px;
  line-height:25px;
  float: none;
}
.txt-principal-vinos-home {
  text-align: center;
  font-size: 16px;
}
.title-vinos-home-dorado, .title-vinos-home {
  font-size: 24px;
}
.btn-vinos-home {
  margin: 15px auto 10px;
}
/*MOBILE footer< */
#logo-footer {
  position: absolute;
  right: 50%;
  width: 150px;
  margin: 30px -75px 30px 0;
}
#list-menu-footer {
  float: none;
  height: 140px;
  width: 109px;
  margin: 110px auto 0;
  padding:0;
  border-bottom: 1px solid #A87E4F;
}
#list-menu-footer a {
  text-align: center;
}
#list-redes-footer {
  width: 109px;
  height: 65px;
  padding: 0 0 20px;
  float: none;
  margin: 20px auto;
  display: block;
  border-bottom: 1px solid #A87E4F;
}
.ico-footer {
  width: 40px;
  margin-right: 10px;
}
#footer-final {
  border: none;
}
.txt-footer-final {
  float: none;
  text-align: center;
  padding: 0;
}
#contacto {
  background-color: #f6f6f6;
  height: 405px;
}



/* ---------replica-------- */


/* HOME */
#home-replica {
  height: auto;
  min-height: 100px;
  background-image:none;
  margin-top: 140px;
}
#home-replica .col-12 {
  padding: 0;
}
.wrapper-title-home-replica {
  margin-top: 0px;
}
.wrapper-titles-home-replica {
  height: 150px;
  margin-bottom: 20px;
}
.title-home-replica{
  text-align: center;
  font-size: 26px;
  line-height: 30px;
  float: none;
  margin: 0 5px 0 0;
  color: #A87E4F;
}
.title-home-dos-replica{
  text-align: center;
  font-size: 26px;
  line-height: 30px;
  float: none;
  margin-top: -8px;
  margin-right: 5px;
  color: #A87E4F;
  margin-bottom: 9px;
}
#vinos-home-replica {
  padding: 0px 0 20px;
}
#vinos-home-2 {
  padding: 0px 0 40px;
}
#vinos-home-replica .col-sm-4, #vinos-home-2-replica .col-sm-4 {
  margin-left: 0px;
}
.wrapper-ficha-tenica {
  margin: 50px 4%;
  padding-left: 7%;
  padding-right: 7%;
  width: 92%;
  float: left;
}
.title-ficha {
  float: none;
  margin: 0;
}
.txt-ficha, .txt-ficha-inicial {
  text-align: left;
  float: none;
}
.img-fichas {
  float: right;
  width: 90%;
  height: auto;
  margin: 20px 5% 0;
}
.btn-ficha-tecnica {
  position:relative;
  height: 50px;
  width: 193px;
  margin: 35px auto 0;
}
.btn-para-comprar {
  margin: 10px auto 20px;
  position: relative;
  bottom: 0;
  left: 0;
}
#home-2-replica {
  height: auto;
  min-height: 100px;
  background-image:none;
}
#vinos-home-2-replica .col-12 {
  padding: 0;
}
/* VINOS */
.title-vinos-home-dorado-replica, .title-vinos-home-replica {
  font-size: 24px;
}
/* FOOTER */
#logo-footer {
  position: absolute;
  right: 50%;
  width: 150px;
  margin: 30px -75px 30px 0;
}
#list-menu-footer {
  float: none;
  height: 140px;
  width: 109px;
  margin: 170px auto 0;
  padding:0;
  border-bottom: 1px solid #A87E4F;
}
#list-menu-footer a {
  text-align: center;
}
#list-redes-footer {
  width: 109px;
  height: 65px;
  padding: 0 0 20px;
  float: none;
  margin: 20px auto;
  display: block;
  border-bottom: 1px solid #A87E4F;
}
.ico-footer {
  width: 40px;
  margin-right: 10px;
}
#footer-final {
  border: none;
}
.txt-footer-final {
  float: none;
  text-align: center;
  padding: 0;
}
#contacto {
  background-color: #f6f6f6;
  height: 405px;
}

/* ----------finreplica------ */
/* ------------replica2--------- */

#home-replica2 {
background-color: #f8f9fa;
height: auto;
min-height: 400px;
}
.wrapper-title-home-replica2 {
margin-top: 60px;
}
.wrapper-titles-home-replica2 {
height: 40px;
}
.title-home-wine-friends {
font-size: 26px;
text-align: center;
float: none;
}
.title-home-wine-friends-dos {
font-size: 28px;
line-height: 88px;
margin: 0;
}
#home-replica2 video {
top: 70%;
}

/* STYLE FORMULARIO MOBILE */
#formulario {
padding: 20px 0 60px;
}
.title-form {
margin-bottom: 20px;
}
#contactForm {
margin: 40px auto 20px;

}
#wrapper-form {
border-bottom: none;
margin-bottom: 0px;
}
.form-group input {
width: 90%;
margin: 0 5% 25px;
}
.btn_enviar {
float: none;
height: 50px;
width: 193px;
line-height: 36px;
margin: 0 auto;
display: block;
}

/* ---------finreplica2 */
}
